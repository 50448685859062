.page-title-wrap {
    position: relative;
    min-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-shape{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.page-title-wrap .col-12 {
    text-align: center;
    color: #fff;
}